import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Kushal Yagnik | Software Developer', // e.g: 'Name | Developer'
  lang: 'EN', // e.g: en, es, fr, jp
  description: `Hey, this is Kushal Yagnik here! I'm a software developer who loves to create elegant user interfaces for web and mobile apps.
                My strengths in front-end technologies include HTML5, CSS3, SASS/SCSS and JavaScript. I am proficient in  JavaScript libraries such as React.js and jQuery. 
                Although, I could easily work on Vue.js, Angular 2+ or other frameworks with short time of self-teaching.
                On the back-end side, I've worked with Node.js, Python, PHP and C#.
                Feel free to connect with me on LinkedIn or write me an email at kushalyagnik@gmail.com.
                I live in the beautiful land of Canada and have roots from the lovely subcontinent of India`, // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: '',
  subtitle: '',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: `I am interested in exploring the potential of computer science, through continuous dedication and strong will to make our world of Internet safer and better. Largely a believer in smart approach, I do not shy away from hard work where needed. I like reading good articles/books on life, nature, science and almost anything about computers. On the technical side, I like creating web apps with remarkable interfaces and good quality code.`,
  paragraphTwo: `Currently learning: Odoo`,
  paragraphThree: `Currently Reading: Clean Code by Robert “Uncle Bob” Martin`,
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'immune-it-thumb-jpeg.jpg',
    title: 'Immune-it',
    info: `An app to keep track of family's immunization history`,
    info2: `Immune-it is a digitized version of the Personal Immunization Record (PIR) card`,
    // url: 'https://immune-it.herokuapp.com',
    demo_url: 'https://www.loom.com/share/0610306c5d9149469e775ff27dcb376a',
    repo: 'https://github.com/KushalYagnik/Immune-it.git', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'bandsite-thumb-jpeg.jpg',
    title: 'BandSite',
    info: 'An intro website for an upcoming band',
    info2: '',
    url: 'https://the-bandsite.herokuapp.com/',
    demo_url: 'https://www.loom.com/share/534a7bcf5e094886a87bd03d068ca85f',
    repo: 'https://github.com/KushalYagnik/Gaspard_Xavier-BandSite.git', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'brainflix-thumb-jpeg.jpg',
    title: 'BrainFlix',
    info: 'A media-streaming platform where users can upload or watch videos',
    info2: '',
    // url: 'https://github.com/KushalYagnik/Kushal-Yagnik-BrainFlix.git',
    demo_url: '',
    repo: 'https://github.com/KushalYagnik/Kushal-Yagnik-BrainFlix.git', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'rick-morty-thumb-jpeg.jpg',
    title: 'Rick-n-Morty',
    info: 'BrainStation Web Dev Mini-Hackathon Project',
    info2: '',
    url: 'https://thirsty-jepsen-e68bb1.netlify.app/',
    demo_url: '',
    // repo: 'https://github.com/KushalYagnik/rick-morty-mini-hackathon.git', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'instock-thumb-jpeg.jpg',
    title: 'InStock',
    info: `An inventory management system to create, monitor and update the items' inventory of registered warehouses`,
    info2: `Collaborative project by a team of 4`,
    // url: 'https://immune-it.herokuapp.com',
    demo_url: 'https://www.loom.com/share/e937d6b97cd64f81a81e42bd8d5006c2',
    repo: 'https://github.com/LucasBernardini/InStock-Naboo.git', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'placeholder.jpg',
    title: 'Bouncing-ball game',
    info: `Break all the bricks using paddle and a bouncing ball to win the game. Don't miss the ball!`,
    info2: ``,
    url: 'https://kushalyagnik.github.io/bouncing-ball-game/',
    // demo_url: 'https://www.loom.com/share/e937d6b97cd64f81a81e42bd8d5006c2',
    repo: 'https://github.com/KushalYagnik/bouncing-ball-game.git', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'placeholder.jpg',
    title: 'GitHub Profile Finder',
    info: `Lookup a GitHub user's public profile quickly`,
    info2: ``,
    url: 'https://gh-profile-lookup.herokuapp.com/',
    // demo_url: 'https://www.loom.com/share/e937d6b97cd64f81a81e42bd8d5006c2',
    // repo: 'https://github.com/KushalYagnik/GitHub_Finder.git', // if no repo, the button will not show up
  },
  // {
  //   id: nanoid(),
  //   img: 'project.jpg',
  //   title: 'BrainFlix',
  //   info: '',
  //   info2: '',
  //   url: '',
  //   repo: 'https://github.com/KushalYagnik/Kushal-Yagnik-BrainFlix.git', // if no repo, the button will not show up
  // },
];

// CONTACT DATA
export const contactData = {
  cta: 'Like what you see? Write to me',
  btn: `Get in touch`,
  email: 'kushalyagnik@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    // {
    //   id: nanoid(),
    //   name: 'twitter',
    //   url: '#',
    // },
    // {
    //   id: nanoid(),
    //   name: 'phone',
    //   url: 'tel:+13437775543',
    // },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://linkedin.com/in/kushal-yagnik',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/KushalYagnik',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
